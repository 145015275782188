import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import Body2 from './../components/Body2'
import Consultoria from '../images/cloud-desk/consultoria_gratis@4x.png'
import FolderShared from '@material-ui/icons/DesktopMac'
import PersonPin from '@material-ui/icons/Language'
import UserVerified from '@material-ui/icons/AttachMoney'
import Search from '@material-ui/icons/Phonelink'
import Accordion from '../components/Accordion'

import { Container } from '../components/styles/Layout.styles'

import { faqCloudServer, IdProtectList, consultingText } from '../components/styles/ProductCloud.styles'
import { titleStyle, titleForm } from '../components/styles/InternalPage.styles'
import { 
  termosBoxWhite 
} from '../components/styles/Contact.styles'
import CloudDeskFrom from '../components/FormWhiteItens'
import { validateRequiredEmail } from '../utils/validations';
const fields = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'Cargo',
    name: 'your-position',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  { 
    label: (
      <span css={termosBoxWhite}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const initialModals = {
  support: false,
  moreInfo: false
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[50]}
              mb={[50]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 500]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    CLOUD DESK HOSTDIME
                  </H4>
                  <H3
                    color='black'
                    variant='highEmphasis'
                    mb={[5, 5, 3]}
                    css={titleStyle}
                  >
                    Sua área de trabalho onde você estiver.
                  </H3>
                  <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 500]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[3]}
                    maxWidth={['90%', 422, 416, 500]}
                  >
                    O Cloud Desk HostDime vai levar o seu computador de trabalho para a nuvem em forma de desktop virtual veloz e seguro, acessível de qualquer lugar e a qualquer instante.
                  </Body2>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    maxWidth={['90%', 422, 416, 500]}
                  >
                    Acesse sua área de trabalho pelo browser ou através de thin clients resistentes a vírus e malwares. Além disso, possui um gerenciamento que permite a facilidade de atualizações simultâneas em todos os desktops virtuais.
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <section style={{ backgroundColor: '#9013fe' }}>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row']}
              mt={[50, 100]}
              mb={[50, 100]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                  alignItems={['center']}
                >
                  <img 
                    src={Consultoria} 
                    style={{
                      width: 100
                    }}
                  />
                  <H4
                    color='white'
                    variant='highEmphasis'
                    mt={[4]}
                    mb={[3]}
                    css={titleForm}
                    maxWidth={['90%', 422, 400, 400]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    A partir de R$20/mês por usuário!
                  </H4>
                  <Body2
                    selected
                    on='onSurface'
                    order={[2, 2, 1]}
                    mb={[4, 4, 0, 0]}
                    maxWidth={['90%', 294, 294, 294]}
                    css={consultingText}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Ganhe uma consultoria. E entenda as possibilidades da Cloud Desk para sua empresa.
                  </Body2>
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    <Div
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <CloudDeskFrom
                        fields={fields}
                        formId={4359}
                        submitText='QUERO RECEBER'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[30, 50]}
            justifyContent={['center']}
          >
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              maxWidth={['90%', 422, 400, 400]}
              style={{
                textAlign: 'center'
              }}
            >
              Vantagens
            </H4>
          </Row>
          <Row
            mt={30}
            mb={46}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', 390, 855, 856]}
              justifyContent={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div
                  marginBottom={[0, 30]}
                  css={IdProtectList}
                >
                  <FolderShared 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Gerenciamento centralizado e descomplicado de desktops</span>
                    <Body2
                      selected
                      on='onSurface'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Elimine qualquer complexidade de gerenciamento de diversas unidades de disco. Com o Cloud Desk, você cria desktops na nuvem em minutos, e realiza atualizações e implantações simultaneamente com mais rapidez e eficiência.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <PersonPin 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Mais facilidade para jornadas office less e home office</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Garanta mais possibilidades de acessos confiáveis para sua equipe por dispositivos autorizados, em qualquer lugar e a qualquer momento. 
                    </Body2>
                  </Div>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <UserVerified 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Economia de energia</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Além de simplificar o uso do desktop remoto para todos da sua equipe, com o Cloud Desk HostDime sua empresa pode chegar em até 98% de economia de energia.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <Search 
                    style={{
                      color: '#9013fe',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Variedade e integração de máquinas</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      A variedade de máquinas que suporta o Cloud Desk é grande: desde desktops completos, passando por thin clients até smartphones.
                    </Body2>
                  </Div>
                </div>
              </Column>
            </Div>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section css={faqCloudServer}>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[50]}
            justifyContent='center'
          >
            <H4>Tire suas Dúvidas</H4>
          </Row>
          <Row
            mb={[40, 70]}
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Accordion 
                  title='O Cloud Desk é uma opção de menor custo?'
                  content='Sim. Ao invés de comprar máquinas de última geração, o poder de processamento deve ser concentrado no Data Center, então o investimento sai das estações de trabalho para servidores mais poderosos que suportem a infraestrutura, permitindo equipamentos mais simples no cliente.'
                />
                <Accordion 
                  title='O que é um Thinclient e porque usá-lo?'
                  content={`Um Thinclient é um computador projetado apenas para acesso a um ambiente de virtualização de desktops, e por isso costuma ter uma arquitetura mais simples, com menos complexidade de implantação e manutenção. Eles não contêm peças móveis (como HD e coolers), então suportam ambientes mais hostis, com vibrações e até poeira e, em caso de quebra, a substituição é simples, pois não possuem configurações nem dados para serem migrados (toda informação está no datacenter).\nMesmo os Thinclients que têm com coolers, por exemplo, oferecem vantagens: normalmente são equipamentos bem menores que os Desktops comuns, mais baratos, com baixo consumo de energia, menor dissipação de calor e mantém o conceito de nenhuma configuração e dados locais. Em alguns projetos os ganhos de espaço físico ou energia elétrica justificam o investimento.`}
                />
                <Accordion 
                  title='Quais o Cloud Desk pode ajudar no gerenciamento dos desktops da minha equipe?'
                  content='Gerenciamento facilitado: Com os softwares da empresa pré-instalados em todos os Desktops virtuais, o deploy de um novo desktop para um novo usuário, por exemplo, seja muito mais simples. Além disso, o connection broker também permite algumas facilidades de gerenciamento, como a criação automática de novos desktops, acesso remoto da equipe de TI sobre o Desktop para suporte ao usuário, reciclagem automática de desktops, integração com virtualização de aplicações e virtualização do perfil dos usuários.'
                />     
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Accordion
                  title='Quais as principais vantagens do Cloud Desk HostDime?'
                  content={`Performance:\nToda a performance depende dos servidores, que no Cloud Desk podem  usar conexões de 10Gbits (normalmente não disponíveis nos Desktops comuns), discos SSD de alta performance (versus discos SSD básicos nos Desktops comuns), placas GPU e memória para execução dos desktops virtuais. Quando a estrutura crescer, basta investir nos servidores e todos os usuários terão ganhos de performance. Isso costuma trazer o prolongamento da vida dos desktops para até 8 anos, versus 5 anos em uma estrutura tradicional.`}
                />
                <Accordion
                  title='Como posso confiar que os dados da minha empresa estarão seguros no Cloud Desk?'
                  content='Os dados dos desktops ficam armazenados nos servidores do Datacenter. Isso permite a execução de backups centralizados, garantindo a disponibilidade dos dados. Pelo mesmo motivo é mais fácil restringir o acesso aos dados e aplicar políticas contra acesso não autorizado; podem ser bloqueados dispositivos externos como Pendrives, e, mesmo se o dispositivo for roubado, não terá nenhum dado nos discos locais sem estar criptografado.'
                />
                <Accordion
                  title='Como a Cloud Desk permite mais economia de energia?'
                  content={`Através da utilização do BOX, que consome 166x menos eletricidade que um computador convencional!
                  \nConfira o cálculo sobre o consumo do BOX, e um comparativo com o consumo de um desktop convencional:
                  \nCorrente: 700mA
                  \nTensão: 5V
                  \nConsumo: 3,5W
                  \nConsumo em kW/h pra uma hora: 0,0035
                  \nConsumo em kW/h pra um dia: 0,084
                  \nConsumo em kW/h pra um mês: 2,16
                  \nComo comparação de consumo, vamos comparar com um pc convencional desktop com fonte de 500W:
                  \nCorrente: 1A
                  \nTensão: 127V
                  \nConsumo: 500W
                  \nConsumo em kW/h pra uma hora: 0,5
                  \nConsumo em kW/h pra um dia: 12
                  \nConsumo em kW/h pra um mês: 360
                  \nAssim, caso você possua um cluster com 166 BOX, terá o mesmo consumo que um desktop convencional.
                `}
                />         
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Cloud Desk HostDime'
        description='Desktops virtualizados, acessíveis de qualquer lugar e a qualquer instante. O Cloud Desk HostDime é ideal para jornadas home office e office less.'
      />
      <PageTemplate
        title='Cloud Desk HostDime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-desk/cloud_desk@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
